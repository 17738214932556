<template>
  <invoice-form />
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import InvoiceForm from "@/modules/invoices/views/Form.vue";
import PaginateProviderMixin from "@/mixins/PaginateProviderMixin";

@Component({
  components: { InvoiceForm },
})
export default class DebtForm extends Mixins(PaginateProviderMixin) {}
</script>
